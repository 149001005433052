<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { dayNumList } from '@/utils/textFile'
export default {
  name: 'farmersBrigade',
  data() {
    return {
      ...api.command.getState(),
      labelList: [],
    }
  },
  created() {
    api.command.search
      .call(this, {
        url: '/commodity/farmTravelRoute/queryRouteLabelDataList',
      })
      .then((res) => {
        this.labelList = res.map((e) => ({
          name: e.labelName,
          value: e.id,
        }))
        this.$forceUpdate()
      })
    api.command.getList.call(this, {
      url: '/commodity/farmTravelRoute/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '线路名称',
          key: 'routeName',
          type: 'input',
        },
        {
          name: '标签',
          type: 'select',
          key: 'selectLabel',
          typeData: this.labelList,
          showSearch: true,
          props: {
            showSearch: true,
            filterOption: (input, option) =>
              option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0,
          },
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'routeName',
          title: '线路名称',
          align: 'left',
          sorter: (a, b) => a.routeName.length - b.routeName.length,
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                <img src={records.bannerUrl || records.previewUrl} />
                <div>{text}</div>
              </div>
            )
          },
          //   onExport: (text, records) => {
          //     return `${records.bannerUrl} / ${text}`
          //   }
        },
        {
          dataIndex: 'dayNum',
          title: '游玩天数',
          type: 'badge',
          align: 'left',
          filters: dayNumList,
          onExport: (records) => {
            return ['其他', '1天', '2天', '3天'][records] || records + '天'
          },
          filterMultiple: false,
          customRender: function (text, records) {
            return <div>{records.dayNum ? records.dayNum + '天' : '其他'}</div>
          },
        },
        {
          dataIndex: 'sightseeingNum',
          title: '观光点数',
          align: 'left',
        },
        {
          dataIndex: 'address',
          title: '游玩地点',
          align: 'left',
          isId: true,
          customRender: function (text, records) {
            return <div>{records.address.substring(0, 8)}</div>
          },
        },
        {
          dataIndex: 'farmLabelDataVOS',
          title: '标签',
          align: 'left',
          getData: (records) => records.farmLabelDataVOS.slice(0, 5).map((e) => e.labelName),
          type: 'tagGroup',
          onExport: (records) => {
            let arr = records.map((e) => {
              return e.labelName
            })
            return arr.toString()
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '下架',
              value: '1',
            },
            {
              text: '上架',
              value: '0',
            },
          ],
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
          filterMultiple: false,
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/commodity/farmTravelRoute/${records.upDown == 0 ? 'down' : 'up'}/${records.id}`,
                  })
                },
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/marketingCenter/farmersBrigadeDetail?id=${records.id}`),
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/commodity/farmTravelRoute/${records.id}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/marketingCenter/farmersBrigadeDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.delPut.call(this, {
                  url: '/commodity/farmTravelRoute/batchDel',
                  params: { ids },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/commodity/farmTravelRoute/batchUp',
                  params: { ids },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/commodity/farmTravelRoute/batchDown',
                  params: { ids },
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
